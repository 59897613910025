import { DirectUpload } from '@rails/activestorage'
import imageCompression from 'browser-image-compression'
import { post } from '@rails/request.js'

const defaultDirectUploadUrl = '/direct_uploads'

export const uploadFile = async (file, delegator, directUploadUrl, compressor = false) => {
  let internalDelegator = null

  if(delegator.progress){
    internalDelegator = {
      directUploadWillStoreFileWithXHR: (request) => {
        request.upload.addEventListener('progress', (event) => delegator.progress(event))
      }
    }
  }

  delegator.add?.(file)

  let compressedFile = null
  if(compressor && isFileImage(file)) {
    try {
      compressedFile = await compressImage(file, compressor)
    }
    catch(e) {}
    finally {
      if(compressedFile) {
        file = compressedFile
      }
    }
  }

  const upload = new DirectUpload(file, directUploadUrl || defaultDirectUploadUrl, internalDelegator)

  upload.create((error, blob) => {
    if(delegator.context) {
      if(error) { delegator.error(error) }
      if(blob) { delegator.success(blob) }
    }
    else {
      delegator(error, blob)
    }
    return {error, blob}
  })
}

export const uploadBase64 = async (data, filename, callback, directUploadUrl, compressor = false) => {
  const file = await base64toFile(data, filename)
  return await uploadFile(file, callback, directUploadUrl, compressor)
}

export const createAssetItem = async (blob, url, callback) => {
  const reqBody = { asset_item: { file: blob.signed_id } }
  const response = await post(url, { body: JSON.stringify(reqBody), responseKind: 'json', contentType: 'application/json' })

  if(response.ok) {
    response.json.then(response => callback(response))
  }
  else { callback() }

  return response
}


export const base64toFile = async (data, filename) => {
  const contentType = contentTypeFromBase64(data)
  const blob = await base64toBlob(data)
  return new File([blob], filename, { type: contentType })
}

export const isFileImage = (file) => {
  const acceptedImageTypes = ['image/jpeg', 'image/png']

  return file && acceptedImageTypes.includes(file['type'])
}

export const compressImage = async (file, options) => {
  options = {...{ useWebWorker: true, maxSizeMB: 2, maxWidthOrHeight: 3840 }, ...options }
  return await imageCompression(file, options)
}

const base64toBlob = async (data) => {
  const base64Response = await fetch(data)
  return await base64Response.blob()
}

const stringBetweenStrings = (startStr, endStr, str) => {
  const pos = str.indexOf(startStr) + startStr.length
  return str.substring(pos, str.indexOf(endStr, pos))
}

const contentTypeFromBase64 = (data) => stringBetweenStrings ('data:', ';base64', data)
