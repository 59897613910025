import { Controller } from '@hotwired/stimulus'
import { uploadFile } from '~js/helpers/file_upload/utils'
import * as delegators from '~js/helpers/file_upload/delegators'

export default class extends Controller {
  static targets = ['input', 'list', 'dropzone']
  static values = {
    delegatorKey: String,
    compressor: { type: Object, default: null },
  }

  openFileBrowser() { this.inputTarget.click() }

  change() {
    this.processFiles(this.inputTarget.files)
    this.inputTarget.value = null
  }

  drop(event) {
    event.preventDefault()
    this.dropzoneTarget.classList.remove('hover')
    this.processFiles(event.dataTransfer.files)
  }

  processFiles(files) {
    if(files.length === 0)  return

    if(this.inputTarget.multiple) Array.from(files).forEach(file => this.uploadFile(file))
    else this.uploadFile(files[0])
  }

  uploadFile(file) {
    const delegator = delegators[`create${this.delegatorKeyValue}Delegator`](this)
    uploadFile(file, delegator, null, this.compressorValue)
  }

  dragover(event) {
    event.preventDefault()
    return true
  }

  dragenter() {
    this.dropzoneTarget.classList.add('hover')
  }

  dragleave() {
    this.dropzoneTarget.classList.remove('hover')
  }

  dragend() {
    this.dropzoneTarget.classList.remove('hover')
  }
}
