export class BaseUploadDelegator {
  constructor(context, callbacks) {
    this.context = context
    this.callbacks = callbacks ?? {}

    this.input = this.context.inputTarget
    this.list = this.context.listTarget
    this.form = this.input.closest('form')
    this.actionUrl = this.form.getAttribute('action')
  }

  add(file) { this.callbacks.add?.bind(this)(file) }

  progress(event) { this.callbacks.progress?.bind(this)(event) }

  success(blob) { this.callbacks.success?.bind(this)(blob) }

  error(error) { this.callbacks.blob?.bind(this)(error) }
}
