import { BaseUploadDelegator } from '~js/helpers/file_upload/delegators/base_delegator'
import { createAssetItem } from '~js/helpers/file_upload/utils'

export const createAssetItemDelegator = (context) => {
  return new BaseUploadDelegator(context, {
    add: function() {
      const uploadHandler = document.createElement('div')
      uploadHandler.classList.add('assets-manager-item')
      uploadHandler.dataset.controller = 'asset-item'
      uploadHandler.innerHTML = '<progress max="100" value="0">0%</progress>'
      this.uploadHandler = uploadHandler
      this.list.insertAdjacentElement('beforeend', this.uploadHandler)
      this.uploadProgress = this.uploadHandler.querySelector('progress')
    },
    progress: function(event) {
      const percentage = parseInt((event.loaded / event.total) * 100)
      this.uploadProgress.setAttribute('value', percentage)
    },
    success: function(blob) {
      createAssetItem(blob, this.actionUrl, (data) => {
        this.uploadHandler.dataset.assetItemDetailsValue = JSON.stringify(data)
        this.uploadHandler.id = `asset_item_${data.id}`
      })
    },
    error: function() {
      this.uploadHandler.remove()
    }
  })
}
