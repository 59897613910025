import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    pattern: String
  }

  connect() {
    this.element.addEventListener('input', this.handleInput.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('input', this.handleInput)
  }

  patternMatch(event) {
    let input = event.currentTarget.value
    let template = this.patternValue

    try {
      let j = 0
      let plaintext = ''
      let countj = 0
      while (j < template.length) {
        if (countj > input.length - 1) {
          template = template.substring(0, j)
          break
        }

        if (template[j] == input[j]) {
          j++
          countj++
          continue
        }

        if (template[j] == 'x') {
          template =
            template.substring(0, j) + input[countj] + template.substring(j + 1)
          plaintext = plaintext + input[countj]
          countj++
        }
        j++
      }

      return template
    } catch {
      return ''
    }
  }

  handleInput(event) {
    event.currentTarget.value = this.patternMatch(event)
  }
}
